<template lang="pug">
.no-data(v-if="!penalties?.length") Данные отсутствуют
.lot-detail-table(v-else)
  .header.header-layout.side-padding
    .td Причина
    .td.adaptive-view Подробности
    .td.full-view Документ
    .td.full-view Дата
    .td.full-view Сумма

  .grid-layout.side-padding(
    v-for="(penalty, index) of penalties"
    :key="index"
  )
    .td.flex-column
      span {{ penalty.reasonTitle }}
      span.adaptive-view.label от {{ formatDateTime(penalty.documentDate) }}
    .inner-table
      .td.flex-column
        span.label.adaptive-view Документ:
        span {{ penalty.documentName }}
      .td.full-view {{ formatDateTime(penalty.documentDate) }}
      .td.flex-column
        span.label.adaptive-view Стоимость:
        span {{ penalty.cost?.original ? costFormatterExtended(penalty.cost.original) : 'Неизвестно' }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatDateTime } from "@/utils/formatter/dateFormatter";
import { costFormatterExtended } from "@/utils/formatter/costFormatter";

import type { PropType } from "vue";
import type { PenaltyLDI } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "PenaltiesInfo",
  methods: {
    costFormatterExtended,
    formatDateTime,
  },
  props: {
    penalties: Array as PropType<PenaltyLDI[]>,
    default: () => ([]),
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';

.header-layout {
  display: grid;
  grid-template-columns: 3fr minmax(140px, 1fr) minmax(140px, 1fr) minmax(140px, 1fr);
}
.grid-layout {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(420px, 1fr);
}

.inner-table {
  display: grid;
  grid-template-columns: minmax(140px, 1fr) minmax(140px, 1fr) minmax(140px, 1fr);
}

.adaptive-view {
  display: none;
}

.flex-column {
  display: flex;
  flex-flow: column;
  gap: 4px;
}

.label {
  font-size: var(--detail-table--size--info);
  color: var(--secondary-text-color);
}

@media (max-width: 860px) {
  .header-layout,
  .grid-layout {
    grid-template-columns: 4fr minmax(100px, 2fr);
  }

  .inner-table {
    grid-template-columns: min-content;
    padding: 10px 8px;
    box-sizing: border-box;
    gap: 6px;

    .td {
      padding: 0;
    }

    .flex-column {
      gap: 2px;
    }
  }

  .adaptive-view {
    display: flex;
  }

  .full-view {
    display: none;
  }
}
</style>
