<template lang="pug">
.lot-item__error
  .lot-error-content
    .title К сожалению, тендер не найден
    .info Возможно, произошла непредвиденная ошибка, или Вы воспользовались неисправной ссылкой
    ui-button.button(type="secondary" @click="goToMainPage") Перейти на главную
  .lot-error-content.empty
    el-empty(description="Тендер не найден")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { getMainPageNavigateRoute } from "~/router/auth";
import UiButton from "@/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "LotItemError",
  components: {
    UiButton,
  },
  setup() {

    const router = useRouter();

    function goToMainPage() {
      router.push(getMainPageNavigateRoute());
    }

    return {
      goToMainPage,
    }
  }
})
</script>

<style scoped lang="scss">
.lot-item__error {
  max-width: var(--main-content-width);
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--main-color-white);
  border-radius: 10px;
  box-shadow: 0 0 20px 10px rgba(222, 222, 222, 0.5);
  padding: 32px 40px;

  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: space-between;

  height: 300px;
}

.lot-error-content {
  display: flex;
  flex-flow: column;
  gap: 32px;

  .title {
    color: #409eff;
    font-size: 24px;
    font-weight: bold;
  }

  .button {
    width: fit-content;
  }

  .info {
    color: #324059;
    font-size: 16px;
    font-weight: 300;
  }
}

@media(max-width: 640px) {
  .lot-error-content {
    .title {
      font-size: 20px;
    }
    .info {
      font-size: 14px;
    }
  }
}

@media(max-width: 520px) {
  .lot-item__error {
    height: unset;
    padding: 32px 32px;
  }
  .lot-error-content {
    gap: 16px;
  }
  .lot-error-content.empty {
    display: none;
  }
}
</style>
