import { ref } from "vue";
import { scrollIntoView } from "@/utils/scroller/documentScroller";
import type { Ref } from "vue";

export function usePagination<T>(fullData: T[], pageSize: Ref<number>, blockId?: string) {

  const totalResults = ref<number>(0);
  const totalPages = ref<number>(1);

  const page = ref<number>(1);
  const data = ref([]);

  function updateValues() {
    page.value = 1;

    totalResults.value = Number(fullData.length)
    totalPages.value = Math.ceil(fullData.length === 0 ? 1 : (fullData.length / pageSize.value))

    data.value = fullData.slice(pageSize.value * (page.value - 1), pageSize.value * page.value)
  }

  function setPage(p: number, id: string) {
    page.value = p;
    data.value = fullData.slice(pageSize.value * (p - 1), pageSize.value * (p))

    if (blockId) scrollIntoView(blockId, 'start')
  }

  updateValues()

  return {
    data,
    page,
    totalResults,
    totalPages,
    setPage,
    updateValues,
  }
}
