<template lang="pug">
#procurement-procedure
  .more-info__line
    span.card-label Дата и время публикации
    .card-value
      span(v-if="lotFullInfo?.datePublic")
        span.date {{ formatDateTime(lotFullInfo?.datePublic) }}
        span.time {{ timezone }}
      span(v-else) Информация отсутствует
  .more-info__line
    span.card-label Дата и время окончания подачи заявок
    .card-value
      span(v-if="lotFullInfo?.dateApplication")
        span.date {{ formatDateTime(lotFullInfo?.dateApplication) }}
        span.time {{ timezone }}
      span(v-else) Информация отсутствует
  .more-info__line
    span.card-label Дата окончания срока рассмотрения заявок участников
    .card-value
      span(v-if="lotFullInfo?.dateExam")
        span.date {{ formatDateTime(lotFullInfo?.dateExam) }}
        span.time {{ timezone }}
      span(v-else) Информация отсутствует
  .more-info__line
    span.card-label Дата и время проведения аукциона в электронной форме
    .card-value
      span.date-time(v-if="lotFullInfo?.dateAuction")
        span.date {{ isCorrectDateAuction || isDateAuctionCalculatedByFZ44 ? formatDateTime(lotFullInfo?.dateAuction) : formatDateDMY(lotFullInfo?.dateAuction) }}
        span.time(v-if="isCorrectDateAuction || isDateAuctionCalculatedByFZ44") {{ timezone }}
        old-warning(v-if="!isCorrectDateAuction && !isDateAuctionCalculatedByFZ44" label="Время на площадке не указано")
        old-warning(v-else-if="isDateAuctionCalculatedByFZ44")
          .warning Дата и время торгов были пересчитаны согласно правилам для закупок 44-ФЗ и типа "Электронный аукцион", а именно "Дата окончания подачи заявок" + 2 часа
      span(v-else) Информация отсутствует
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { formatDateDMY, formatDateTime, getTimezoneString } from "@/utils/formatter/dateFormatter";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import type { PropType } from "vue";
import type { LotDetailInterface } from "@/stores/search/LotItemInterface";
import OldWarning from "~/components/ui/tooltip/OldWarning.vue";

export default defineComponent({
  name: "3ProcurementProcedure",
  components: {
    OldWarning,
  },
  props: {
    lotFullInfo: Object as PropType<LotDetailInterface>,
  },
  methods: {
    formatDateDMY,
    formatDateTime,
  },
  setup(props) {

    const { me } = useUserAccess();
    const timezone = computed(() => getTimezoneString(me.value.timezone, false))

    const isCorrectDateAuction = computed(() => props.lotFullInfo.hasOwnProperty('isCorrectDateAuction') ? props.lotFullInfo.isCorrectDateAuction : true);
    const isDateAuctionCalculatedByFZ44 = computed(() => props.lotFullInfo.hasOwnProperty('isDateAuctionCalculatedByFZ44') ? props.lotFullInfo.isDateAuctionCalculatedByFZ44 : false);

    return {
      timezone,
      isCorrectDateAuction,
      isDateAuctionCalculatedByFZ44,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';

.time {
  color: var(--main-placeholder-color);
  padding-right: 6px;
}

.date {
  padding-right: 6px;
}

.warning {
  max-width: 240px;
}

.date-time {
  display: flex;
  flex-flow: row;
}
</style>
