<template lang="pug">
#hosting-organization
  .more-info__line
    span.card-label Организация, осуществляющая размещение
    span.card-value {{ (lotFullInfo?.organizationResponsible || [])[0]?.title || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Почтовый адрес
    span.card-value {{ (lotFullInfo?.organizationResponsible || [])[0]?.postalAddress || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Место нахождения
    span.card-value {{ (lotFullInfo?.organizationResponsible || [])[0]?.legalAddress || 'Информация отсутствует' }}
  .more-info__line(v-if="isAuth")
    span.card-label Ответственное должностное лицо
    span.card-value {{ (lotFullInfo?.contactPerson || [])[0]?.fio || 'Информация отсутствует' }}
  .more-info__line(v-if="isAuth")
    span.card-label Адрес электронной почты
    span.card-value {{ (lotFullInfo?.contactPerson || [])[0]?.email || 'Информация отсутствует' }}
  .more-info__line(v-if="isAuth")
    span.card-label Номер контактного телефона
    span.card-value {{ (lotFullInfo?.contactPerson || [])[0]?.phone || 'Информация отсутствует' }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import type { PropType } from "vue";
import type { LotDetailInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "4HostingOrganization",
  props: {
    lotFullInfo: Object as PropType<LotDetailInterface>,
  },
  setup() {

    const { isAuth } = useUserAccess();

    return {
      isAuth,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';
</style>
