<template lang="pug">
#lot-detail-page(:class="isAuth && 'auth'")
  lot-detail
    template(#inner)
      call-for-registration(v-if="!isAuth")

  system-capabilities(v-if="!isAuth" from-card)
  ClientOnly
    call-for-registration(v-if="!isAuth" mini)
    support-block(v-if="!isAuth" from-card)
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import LotDetail from "@/components/pages/detail/lot/LotDetail.vue";
import CallForRegistration from "@/components/pages/landing/CallForRegistration.vue";
import SystemCapabilities from "@/components/pages/landing/SystemCapabilities.vue";
import SupportBlock from "@/components/pages/landing/SupportBlock.vue";

export default defineComponent({
  name: "LotDetailPage",
  components: {
    LotDetail,
    CallForRegistration,
    SystemCapabilities,
    SupportBlock,
  },
  setup() {

    const { isAuth } = useUserAccess();

    return {
      isAuth,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#lot-detail-page.auth {
  @include page-view;
  padding-top: 32px;
}

#lot-detail-page:not(.auth) {
  @include adaptive-view;

  display: flex;
  flex-flow: column;
  gap: 32px;
  padding: 32px 24px;
}

@media(max-width: 380px) {
  #lot-detail-page:not(.auth) {
    padding: 24px 12px;
  }
}
</style>
