<template lang="pug">
#requirements-participants
  .more-info__line
    span.card-label Преимущества
    span.card-value {{ lotFullInfo?.preferences || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Требования к участникам
    span.card-value {{ lotFullInfo?.requirements || 'Информация отсутствует' }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { LotDetailInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "5RequirementsParticipants",
  props: {
    lotFullInfo: Object as PropType<LotDetailInterface>,
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';
</style>
