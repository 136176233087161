<template lang="pug">
.expansive-panel(v-if="showCurrentPanel")
  .expansive-panel-header(@click.stop="toggle")
    ui-icon(
      v-if="iconPosition === 'start'"
      :size="24"
      :icon="visible ? iconAsc : iconDesc"
    )
    .header-font
      slot(name="header")
    ui-icon(
      v-if="iconPosition === 'end'"
      :size="12"
      :icon="visible ? iconAsc : iconDesc"
    )
  .expansive-panel-content(v-show="visible")
    slot(name="content")
</template>

<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent } from "vue";
import { useVModel } from "@vueuse/core";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "ExpansivePanel",
  components: {
    UiIcon,
  },
  props: {
    expansive: {
      type: Boolean,
      default: true,
    },
    iconAsc: {
      type: String,
      default: UiIconNames.Chevron_ArrowUp,
    },
    iconDesc: {
      type: String,
      default: UiIconNames.Chevron_ArrowRight,
    },
    iconPosition: {
      type: String as PropType<"start" | "end">,
      default: "end",
    },
  },
  emits: [
    "update:expansive",
  ],
  setup(props, context) {

    const visible = useVModel(props, 'expansive', context.emit)
    const showCurrentPanel = computed(() => Boolean(context.slots.content));

    function toggle() {
      visible.value = !visible.value;
    }

    function setExpansive(value: boolean) {
      if (visible.value !== value) toggle();
    }

    return {
      visible,
      showCurrentPanel,
      toggle,
      setExpansive,      // используется для раскрытия панели извне компоненты
    };
  },
});
</script>

<style scoped lang="scss">
.expansive-panel {
  width: 100%;

  &_active .expansive-panel-header {
    background-color: #F5F7FA !important;
  }

  .expansive-panel-header {
    display: flex;
    gap: 8px;
    align-items: center;
    min-height: 44px;
    padding: 8px 32px;
    cursor: pointer;
    border: 1px solid var(--lot-border-color);
    background: #f6faff;
    //background: #f8fbfd;
    user-select: none;
    box-sizing: border-box;

    .header-font {
      font-size: 12px;
      font-weight: bold;
      //color: var(--default-blue-color);
      color: var(--main-color-black);
      width: 100%;
      text-transform: uppercase;
    }

    //&:hover {
    //  background: var(--main-color-white);
    //}
  }

  .expansive-panel-content {
    border: 1px solid var(--lot-border-color);
    border-top: none;
    background-color: var(--main-color-white);
  }
}

@media(max-width: 520px) {
  .expansive-panel {
    .expansive-panel-header {
      padding: 8px 16px;
      .header-font {
        font-size: 12px;
      }
    }
  }
}
</style>
