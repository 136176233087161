<template lang="pug">
#common-info
  .more-info__line
    span.card-label Способ определения поставщика (подрядчика, исполнителя)
    span.card-value {{ lotFullInfo.type?.title || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Наименование электронной площадки
    span.card-value {{ lotFullInfo.auctionSite?.title || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Ссылка на закупку
    .card-value
      a.link(v-if="lotFullInfo.govRuId" :href="lotFullInfo.link" target="_blank") {{ lotFullInfo.govRuId }}
      span(v-else) Информация отсутствует
  .more-info__line
    span.card-label Перейти на площадку
    .card-value
      a.link(v-if="lotFullInfo.auctionSite?.title" :href="lotFullInfo?.auctionSite?.link" target="_blank") {{ lotFullInfo.auctionSite?.title }}
      span(v-else) Информация отсутствует
  .more-info__line
    span.card-label Организация, осуществляющая размещение
    span.card-value {{ lotFullInfo.organizationResponsible?.[0]?.title || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Заказчик
    .card-value.link(v-if="lotFullInfo.customers?.length > 1" @click="scrollIntoView('customers-list', 'center')") Перейти к заказчикам
    .card-value.customers-column(v-else)
      template(v-if="!lotFullInfo.customers?.[0]")
        analytic-link(tab="customers" :company="lotFullInfo.customers?.[0]" empty-label="Информация отсутствует")
      template(v-else)
        analytic-link(v-if="lotFullInfo.customers?.[0]?.title" tab="customers" :company="lotFullInfo.customers?.[0]" empty-label="Информация отсутствует")
        div(v-if="lotFullInfo.customers?.[0].inn || lotFullInfo.customers?.[0].kpp")
          template(v-if="lotFullInfo.customers?.[0].inn") ИНН: {{ lotFullInfo.customers?.[0].inn }}
          template(v-if="lotFullInfo.customers?.[0].inn && lotFullInfo.customers?.[0].kpp") {{', '}}
          template(v-if="lotFullInfo.customers?.[0].kpp") КПП: {{ lotFullInfo.customers?.[0].kpp }}
        div(v-if="lotFullInfo.customers?.[0].postalAddress") Почтовый адрес: {{ lotFullInfo.customers?.[0].postalAddress }}
  .more-info__line
    span.card-label Объект закупки
    .card-value.objects-wrapper
      span.objects(:class="!showMore && '_collapsed'") {{ lotFullInfo.purchaseObjects || 'Информация отсутствует' }}
      span.show-more.link(@click="showMore = !showMore") {{ !showMore ? 'Показать полностью' : 'Скрыть' }}
  .more-info__line
    span.card-label Место доставки товара, выполнения работ и оказания услуг
    span.card-value {{ lotFullInfo.deliveryPlace || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Сроки поставки товара, выполнения работ и оказания услуг
    span.card-value {{ lotFullInfo.deliveryTerm || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Этап закупки
    span.card-value {{ lotFullInfo.status?.title || 'Информация отсутствует' }}
  .more-info__line
    span.card-label Закон
    span.card-value {{ lotFullInfo.law?.title || 'Информация отсутствует' }}
  .more-info__line
    span.card-label.hide-on-520 Действия
    span.common-info__actions
      ui-button.adaptive-button(size="large" @click="openTenderService(lotFullInfo, 'application')") Заказать подготовку заявки
      ui-button.adaptive-button(size="large" @click="openTenderService(lotFullInfo, 'claim')") Заказать подготовку жалобы
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from "vue";
import { useTenderService } from "@/use/tenderService/useTenderService";
import { scrollIntoView } from "@/utils/scroller/documentScroller";
import type { PropType } from "vue";
import type { LotDetailInterface } from "@/stores/search/LotItemInterface";

import UiButton from "@/components/ui/button/UiButton.vue";
import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";

export default defineComponent({
  name: "CommonInfo",
  components: {
    UiButton,
    AnalyticLink,
  },
  methods: {
    scrollIntoView,
  },
  props: {
    lotFullInfo: {
      type: Object as PropType<LotDetailInterface>,
      default: () => ({}),
    },
  },
  setup() {

    const showMore = ref(false);
    const { openTenderService } = useTenderService();

    if(import.meta.client) {
      nextTick(() => {
        try {
          const height = document.querySelector(".objects")?.scrollHeight;
          if (height > 101) {
            const showMoreLink = document.querySelector(".show-more");
            showMoreLink.style.display = "block";
          }
        } catch {
          console.log('error')
        }
      })
    }

    return {
      showMore,
      openTenderService,
    };
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/links";
@import '@/assets/styles/table/lotDetail';

.link {
  @include link-mixin;
}

.customers-column {
  display: flex;
  flex-flow: column;
  gap: 4px;
}

.objects {
  overflow-wrap: anywhere;

  &._collapsed {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.show-more {
  display: none;
}
</style>
