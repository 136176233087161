<template lang="pug">
.no-data(v-if="!contracts?.length") Данные отсутствуют
.lot-detail-table(v-else)
  .header.header-layout.side-padding
    .td Контракт
    .td Заказчик
    .td Поставщик
    .td Цена контракта
  .table-row.side-padding(
    v-for="(contract, index) of contracts"
    :key="index + contract.registrationNumber"
  )
    .grid-layout
      .td.contract-number
        .block.contract-link
          a.link(:href="`https://zakupki.gov.ru/epz/contract/contractCard/common-info.html?reestrNumber=${ contract.registrationNumber }`" target="_blank") № {{ contract.registrationNumber }}
          ui-warning.tooltip(label="Ссылка на контракт была сгенерирована автоматически, из-за чего она может вести на несуществующую страницу" size="small")
        .block.adaptive-cost.adaptive
          span.label Цена:
          blurred-span(:label="costFormatterExtended(contract.cost.original)")
        .block
          span.label Дата подписания:
          span {{ formatDateTime(contract.dateSign) }}
        .block
          span.label Дата исполнения:
          span {{ formatDateTime(contract.dateEndFact) }}
      .td.contract-customer.adaptive
        span.label Заказчик:
        analytic-link(v-if="contract.customer" tab="customers" :company="contract.customer")
        span(v-else) Заказчик неизвестен
      .td.contract-supplier.adaptive
        span.label Поставщик:
        blurred-span(:label="contract.supplier ? contract.supplier?.title : 'Участник неизвестен'")
          analytic-link(v-if="contract.supplier" tab="suppliers" :company="contract.supplier")
          span(v-else) Участник неизвестен
      .td.contract-cost
        blurred-span(:label="costFormatterExtended(contract.cost.original)")

    .contracts-details(v-if="contract.purchaseObjectsFull || contract.penalty")
      expansive-panel.contracts-purchase__header(
        v-if="contract.purchaseObjectsFull && contract.purchaseObjectsFull.length > 0"
        v-model:expansive="purchases"
      )
        template(#header) Информация об объектах закупки
        template(#content)
          purchase-objects(blur-info :purchase-objects="contract.purchaseObjectsFull")

      expansive-panel.contracts-penalties__header(
        v-if="contract.penalty && contract.penalty.length > 0"
        v-model:expansive="penalties"
      )
        template(#header) Штрафы
        template(#content)
          penalties(:penalties="contract.penalty")
</template>

<script lang="ts">
import { defineComponent, ref, } from "vue";
import { formatDateTime } from "@/utils/formatter/dateFormatter";
import { costFormatterExtended } from "@/utils/formatter/costFormatter";

import type { PropType } from "vue";
import type { ContractLDI } from "@/stores/search/LotItemInterface";

import ExpansivePanel from "@/components/ui/dropdown/ExpansivePanel.vue";
import PurchaseObjects from "./6_PurchaseObjects.vue";
import Penalties from "./Penalties.vue";
import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";
import OldWarning from "~/components/ui/tooltip/OldWarning.vue";
import BlurredSpan from "~/components/other/BlurredSpan.vue";
import UiWarning from "~/components/ui/tooltip/UiWarning.vue";

export default defineComponent({
  name: "10_Contracts",
  methods: {
    formatDateTime,
    costFormatterExtended,
  },
  components: {
    UiWarning,
    BlurredSpan,
    OldWarning,
    AnalyticLink,
    PurchaseObjects,
    Penalties,
    ExpansivePanel,
  },
  props: {
    contracts: {
      type: Array as PropType<ContractLDI[]>,
      default: () => ([]),
    },
  },
  setup() {

    const penalties = ref(false);
    const purchases = ref(false);

    return {
      penalties,
      purchases,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';
@import '@/assets/styles/mixin/links';

.header-layout,
.grid-layout {
  display: grid;
  grid-template-columns: 3fr minmax(220px, 2fr) minmax(220px, 2fr) minmax(140px, 1fr);
}

.grid-layout {
  grid-template-areas: "contract-number contract-customer contract-supplier contract-cost";

  .contract-number { grid-area: contract-number; }
  .contract-customer { grid-area: contract-customer; }
  .contract-supplier { grid-area: contract-supplier; }
  .contract-cost { grid-area: contract-cost; }
}

.contract-number {
  display: flex;
  flex-flow: column;
  gap: 4px;
}

.contract-link {
  display: flex;
  flex-flow: row;
  gap: 4px;

  .link {
    font-weight: 600;
    @include link-mixin;
  }
}

.tooltip {
  :deep(.warning) {
    max-width: 240px;
  }
}

.adaptive-dates,
.adaptive-cost {
  display: none;
}

.label {
  padding-right: 4px;
  color: var(--main-placeholder-color);
  font-size: var(--detail-table--size--info);
}

.adaptive {
  .label {
    display: none;
  }
}

.table-row {
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.contracts-details {
  display: flex;
  flex-flow: column;
  gap: 12px;
  padding-bottom: 12px;
}

.contracts-penalties__header {
  ::v-deep(.expansive-panel-header) {
    .header-font {
      color: var(--main-red-color);
      text-transform: uppercase;
      //font-size: 13px;
    }
  }
}

.contracts-purchase__header {
  ::v-deep(.expansive-panel-header) {
    .header-font {
      color: var(--main-color-blue-dark);
      text-transform: uppercase;
      //font-size: 13px;
    }
  }
}

@media(max-width: 1040px) {
  .contract-cost {
    display: none;
  }

  .adaptive-dates,
  .adaptive-cost {
    display: inline-block;
  }

  .adaptive {
    .label {
      display: block;
    }
  }

  .label {
    display: block;
  }

  .header {
    display: none;
  }

  .grid-layout  {
    grid-template-columns: 3fr minmax(220px, 2fr) minmax(220px, 2fr);
  }
}

@media (max-width: 960px) {
  .grid-layout {
    grid-template-areas: "contract-number contract-customer"
                         "contract-number contract-supplier";
    grid-template-columns: 2fr minmax(240px, 2fr);
    grid-template-rows: min-content auto;
  }
}

@media (max-width: 620px) {
  .lot-detail-table {
    .td {
      padding: 4px 8px;
    }
  }
  .contract-number {
    gap: 8px;
  }
  .side-padding {
    padding: 8px 24px;
  }
  .grid-layout {
    grid-template-areas: "contract-number"
                         "contract-customer"
                         "contract-supplier";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }
}

@media(max-width: 520px) {
  .side-padding {
    padding: 8px;
  }
}
</style>
