<template lang="pug">
.customers__search.more-info__line
  .customers__search-column
    .customers__search-column-title.card-value Заказчик
    .customers__search-column-input
      base-input(v-model="filterInputTitle" placeholder="Заказчик, ИНН, КПП")
  .customers__search-column
    .customers__search-column-title.card-value Почтовый адрес
    .customers__search-column-input
      base-input(v-model="filterInputAddress" placeholder="Почтовый адрес")
.customers__content
  .more-info__line.customers__search(
    v-for="customer of preparedCustomers"
    :key="customer.inn"
  )
    .card-value.customers__search-column
      analytic-link(tab="customers" :company="customer")
      div
        template(v-if="customer.inn") ИНН: {{ customer.inn }}
        template(v-if="customer.inn && customer.kpp") {{', '}}
        template(v-if="customer.kpp") КПП: {{ customer.kpp }}
    span.card-value.content.customers__search-column {{ customer.postalAddress }}
  .no-content(v-if="preparedCustomers.length === 0") Нет результатов, удовлетворяющих введенным условиям
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";

import type { PropType } from "vue";
import type { SimpleCompanyI } from "@/stores/manuals/ManualsInterface";

import BaseInput from "@/components/ui/base/BaseInput.vue";
import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";

export default defineComponent({
  name: "Customers",
  components: {
    AnalyticLink,
    BaseInput,
  },
  props: {
    customers: {
      type: Array as PropType<SimpleCompanyI[]>,
      default: () => ([]),
    },
  },
  setup(props) {

    const filterInputTitle = ref('');
    const filterInputAddress = ref('');

    const preparedCustomers = computed<SimpleCompanyI[]>(() => {
      const filterTitle = filterInputTitle.value.toLowerCase();
      const filterAddress = filterInputAddress.value.toLowerCase();

      return (props.customers || [])
        .filter(customer => (
          customer.title?.toLowerCase().includes(filterTitle)
          || customer.inn?.toString().includes(filterTitle)
          || customer.kpp?.toString().includes(filterTitle)
        ) && customer.postalAddress?.includes(filterAddress))
    })

    return {
      filterInputTitle,
      filterInputAddress,
      preparedCustomers,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';

.customers__content {
  max-height: 600px;
  overflow: auto;

  .no-content {
    font-size: 12px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.customers__search {
  //width: inherit;
  //display: flex;
  //justify-content: space-between;

  .customers__search-column {
    //width: 50%;
    box-sizing: border-box;

    .customers__search-column-title {
      padding: 8px 0;
      font-weight: 500;
    }
  }
}
</style>
