<template lang="pug">
.lot-item__loading
  ._icon
  ._mask._mask_1
  ._mask._mask_2
  ._mask._mask_3
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LotItemLoading",
});
</script>

<style scoped lang="scss">
.lot-item__loading {
  //margin: 30px 0 15px 0;
  max-width: var(--main-content-width);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  background-color: #f5fbff;
  border-radius: 10px;
  box-shadow: 0 0 20px 10px rgba(222, 222, 222, 0.5);

  display: grid;
  grid-template-rows: 3fr 1fr;
  grid-template-columns: 70% 30%;

  padding: 8px;
  height: 300px;

  ._icon {
    position: absolute;
    animation: loading-custom 4s linear infinite;
    height: inherit;
    width: 0;
    box-shadow: 0 0 500px 200px var(--main-color-blue);
  }

  ._mask {
    background-color: white;
    border-radius: 10px;
    margin: 8px;
    z-index: 2;

    &_1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    &_2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    &_3 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;

      display: flex;
      justify-content: center;
    }
  }
}

@keyframes loading-custom {
  0% {
    transform: translateX(0px) rotate(45deg);
    opacity: 0;
  }
  33% {
    transform: translateX(347px) rotate(45deg);
    opacity: 1;
  }
  66% {
    transform: translateX(694px) rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: translateX(1040px) rotate(45deg);
    opacity: 0;
  }
}
</style>
