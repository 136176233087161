<template lang="pug">
.no-data(v-if="!purchaseObjects") Данные отсутствуют
.lot-detail-table(v-else id="purchase-objects")
  .header.grid-layout.side-padding
    .td
      span.full-view Наименование товара, работы, услуги, код ОКПД 2
      span.adaptive-view Наименование
    .td.full-view Ед. изм.
    .td.full-view Кол-во
    .td.full-view Цена за ед.изм.
    .td Стоимость

  .table-row.grid-layout.side-padding(
    v-for="purchaseObject of data"
    :key="purchaseObject.title"
  )
    .td.flex.unit-name
      span {{ purchaseObject.title }}
      span.okpd-info(v-if="!purchaseObject.code") Код ОКПД2 не указан
      span.okpd-info(v-else) {{ purchaseObject.code }} - {{ purchaseObjectsOkpds?.find(e => e.code === purchaseObject.code)?.title || 'Название кода не определено' }}
    .td.adaptive-view
      .flex
        .total
          blurred-span(:show="!blurInfo" :label="costFormatterExtended(purchaseObject.contractCostTotal.original)")
        .unit
          blurred-span(:show="!blurInfo" :label="costFormatterExtended(purchaseObject.contractCostPerUnit.original)")
          |
          | x
          | {{ purchaseObject.quantity || 1 }}
          | {{ purchaseObject.unitTitle }}
    .td.full-view.unit-title {{ purchaseObject.unitTitle }}
    .td.full-view.quantity {{ purchaseObject.quantity }}
    .td.full-view.unit-cost
      blurred-span(:show="!blurInfo" :label="costFormatterExtended(purchaseObject.contractCostPerUnit.original)")
    .td.full-view.total-cost
      blurred-span(:show="!blurInfo" :label="costFormatterExtended(purchaseObject.contractCostTotal.original)")

  .table-row.grid-layout.side-padding
    .td.full-view
    .td.full-view
    .td.full-view
    .td.total ИТОГО:
    .td.total
      blurred-span(:show="!blurInfo" :label="costFormatter('RUB').format(totalCost)")

  .table-row.side-padding(v-if="purchaseObjects?.length > minPageSize")
    .td.pagination-wrapper
      ui-pagination(
        small
        :pageSize="pageSize"
        :current-page="page"
        :total="totalPages"
        :count-results="data.length"
        :total-results="totalResults"
        :page-sizes="[minPageSize, 40, 60, 80, 100]"
        @update:current-page="setPage"
        @update:page-size="setPageSize"
      )
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { costFormatter, costFormatterExtended } from "@/utils/formatter/costFormatter";
import { usePagination } from "@/use/pagintaion/usePagination";
import { useApi } from "@/use/api/useApi";

import type { PropType } from "vue";
import type { PurchaseObjectsLDI } from "@/stores/search/LotItemInterface";
import type { OkpdTagI } from "~/stores/manuals/ManualsInterface";

import UiPagination from "@/components/ui/pagination/UiPagination.vue";
import BlurredSpan from "~/components/other/BlurredSpan.vue";

export default defineComponent({
  name: "6PurchaseObjects",
  components: {
    BlurredSpan,
    UiPagination,
  },
  props: {
    purchaseObjects: {
      type: Array as PropType<PurchaseObjectsLDI[]>,
      default: () => ([]),
    },
    blurInfo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    costFormatter,
    costFormatterExtended,
  },
  setup(props) {

    const minPageSize = 20;
    const pageSize = ref(minPageSize);

    const {
      data,
      page,
      totalResults,
      totalPages,
      setPage,
      updateValues,
    } = usePagination<PurchaseObjectsLDI>(props.purchaseObjects || [], pageSize, 'purchase-objects');

    const totalCost = props.purchaseObjects?.map(e => e?.contractCostTotal?.original?.cost || 0).reduce((a: any, curr: any) => a + curr, 0);

    function setPageSize(size: number) {
      pageSize.value = size
      updateValues()
    }

    const okpds = [...new Set(props.purchaseObjects?.map(e => e.code)?.filter(Boolean) || [])];
    const purchaseObjectsOkpds = ref<OkpdTagI[]>([])

    if (okpds.length > 0) {
      useApi().filtered.fetchFilteredEntity<OkpdTagI[]>('okpd', { fullCode: okpds })
        .then((responseData) => purchaseObjectsOkpds.value = responseData || [])
    }

    return {
      data,
      page,
      totalResults,
      totalPages,
      pageSize,
      minPageSize,
      setPage,
      setPageSize,
      totalCost,
      purchaseObjectsOkpds,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';

#purchase-objects {
  scroll-margin-top: 84px;
}

.lot-detail-table {
  .grid-layout {
    display: grid;
    grid-template-columns: 4fr repeat(4, minmax(120px, 1fr));
  }
}

.adaptive-view {
  display: none;
}

.flex {
  display: flex;
  flex-flow: column;
  gap: 6px;
}

.okpd-info {
  font-size: var(--detail-table--size--info);
  line-height: var(--detail-table--height--info);
  color: var(--secondary-text-color);
}

.unit-title {
  overflow-wrap: anywhere;
}

.unit {
  color: var(--main-placeholder-color);
  font-size: var(--detail-table--size--info);
  overflow-wrap: anywhere;
}

.unit-name {
  overflow-wrap: anywhere;
}

@media (max-width: 860px) {
  .adaptive-view {
    display: flex;
  }

  .full-view {
    display: none;
  }

  .total {
    font-weight: 600;
  }

  .lot-detail-table {
    .grid-layout {
      grid-template-columns: 4fr minmax(100px, 2fr);
    }
  }
}

@media (max-width: 520px) {
  .pagination-wrapper {
    :deep(.pag-wrapper) {
      font-size: 11px;
      line-height: 14px;
      flex-flow: row wrap;
    }

    :deep(.pagination) {
      .pag-buttons {
        flex-flow: row wrap;
      }
    }
  }
}
</style>
