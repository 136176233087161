<template lang="pug">
#lot_detail
  #panel_action(v-if="lotFullInfo")
    ui-button(type="secondary" :icon-left="UiIconNames.Chevron_Up" @click="expansiveAll(false)") Свернуть панели
    ui-button(type="secondary" :icon-left="UiIconNames.Chevron_Down" @click="expansiveAll(true)") Развернуть панели
  lot-item-loading(v-if="status === 'pending'")
  lot-card-search(
    v-else-if="lotFullInfo"
    from-card
    :lot="lotFullInfo"
    @show-create-contract="openCreateContractDialog"
  )
  lot-item-error(v-else)
  slot(name="inner")
  template(v-for="(panel, index) of panels" :key="panel.name")
    expansive-panel(
      v-if="lotFullInfo && !panel.hide"
      :id="panel.id"
      v-model:expansive="expansive[index]"
    )
      template(#header) {{ panel.name }}
      template(#content)
        component(:is="panel.component" v-bind="panel.props")
    //ui-button.register-button(v-if="!isAuth && index === 5" @click="showDialog('registration')") Получить полный доступ на 7 дней бесплатно

ClientOnly
  create-contract-dialog(
    v-model:show="showCreateContract"
    :cost="createContractPayload.cost"
    :responsible="createContractPayload.responsiblePerson"
    :lot-id="createContractPayload.lotId"
    @on:hide="onHideDialog"
  )
</template>

<script lang="ts">
import {defineComponent, ref, shallowRef} from "vue";
import {useRoute} from "vue-router";
import {useApi} from "@/use/api/useApi";

import ExpansivePanel from "@/components/ui/dropdown/ExpansivePanel.vue";
import LotItemLoading from "@/components/pages/detail/lot/LotItemLoading.vue";
import CommonInfo from "@/components/pages/detail/info/1_0_CommonInfo.vue";
import Customers from "@/components/pages/detail/info/1_1_Customers.vue";
import Cost from "@/components/pages/detail/info/2_Cost.vue";
import ProcurementProcedure from "@/components/pages/detail/info/3_ProcurementProcedure.vue";
import HostingOrganization from "@/components/pages/detail/info/4_HostingOrganization.vue";
import RequirementsParticipants from "@/components/pages/detail/info/5_RequirementsParticipants.vue";
import PurchaseObjects from "@/components/pages/detail/info/6_PurchaseObjects.vue";
import Documents from "@/components/pages/detail/info/Documentation.vue";
import Results from "@/components/pages/detail/info/9_Results.vue";
import Contracts from "@/components/pages/detail/info/10_Contracts.vue";
import LotCardSearch from "@/components/cards/LotCardSearch.vue";
import CreateContractDialog from "@/components/mainStreamPage/lotItem/dialogs/CreateContractDialog.vue";
import LotItemError from "@/components/pages/detail/lot/LotItemError.vue";
import UiButton from "@/components/ui/button/UiButton.vue";

import type {LotDetailInterface} from "@/stores/search/LotItemInterface";
import type {CostItemI} from "@/stores/manuals/ManualsInterface";
import type {ManagerI} from "@/stores/auth/UserInterface";

import {Tabs} from "@/router/tabs";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import { useLandingLogin } from "~/use/auth/useLandingLogin";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

export default defineComponent({
  name: "LotDetail",
  components: {
    UiButton,
    LotItemError,
    LotItemLoading,
    ExpansivePanel,
    CommonInfo,
    Customers,
    Cost,
    ProcurementProcedure,
    HostingOrganization,
    RequirementsParticipants,
    PurchaseObjects,
    Documents,
    Results,
    Contracts,
    LotCardSearch,
    CreateContractDialog,
  },
  setup() {
    const panels = useState(() => shallowRef([] as any));

    const route = useRoute();

    const expansive = ref(new Array(11).fill(true))

    const { showDialog } = useLandingLogin();
    const { isAuth } = useUserAccess();

    function initPanels(info: LotDetailInterface) {
      panels.value = [
        {name: 'Общая информация о закупке', component: 'CommonInfo', props: {lotFullInfo: info}},
        {name: 'Заказчики', component: 'Customers', props: {customers: info?.customers}, hide: (info?.customers?.length || 0) <= 1, id: 'customers-list'},
        {name: 'Начальная (максимальная) цена контракта, цена обеспечений', component: 'Cost', props: {lotFullInfo: info}},
        {name: 'Информация о процедуре закупки', component: 'ProcurementProcedure', props: {lotFullInfo: info}},
        {name: 'Информация об организации, осуществляющей определение поставщика (подрядчика, исполнителя)', component: 'HostingOrganization', props: {lotFullInfo: info}},
        {name: 'Преимущества, требования к участникам', component: 'RequirementsParticipants', props: {lotFullInfo: info}},
        {name: 'Информация об объектах закупки', component: 'PurchaseObjects', props: {purchaseObjects: info?.purchaseObjectsFull}},
        {name: 'Документация закупки', component: 'Documents', props: {docs: info?.files}},
        {name: 'Протоколы', component: 'Documents', props: {docs: info?.protocols}},
        {name: 'Итоги', component: 'Results', props: {results: info?.results}},
        {name: 'Контракты', component: 'Contracts', props: {contracts: info?.contracts}},
      ];
    }

    function getLotFullInfo() {
      return (route.name === Tabs.LotDetail
        ? useApi().lots.byTLot(Number(route.params.tLotId))
        : useApi().lots.byGovRu(route.params.lotNumber as string, route.params.govRuId as string))
    }

    function expansiveAll(value: boolean) {
      expansive.value = expansive.value.map(() => value)
    }

    const {error, data: lotFullInfo, status} = useAsyncData<LotDetailInterface>('lot', getLotFullInfo, {
      transform: v => {
        initPanels(v);
        return v;
      }
    });

    useHead(computed(() => ({
      title: lotFullInfo.value?.lotTitle ? `Тендер №${lotFullInfo.value?.govRuId}: ${lotFullInfo.value?.lotTitle}${ lotFullInfo.value?.lotNumber === 1 ? '' : ', лот №' + lotFullInfo.value?.lotNumber } - ТендерМонитор` : 'О лоте',
      meta: [
        { name: 'description', content: () => lotFullInfo.value?.lotTitle ? `Закупка №${lotFullInfo.value?.govRuId}: ${lotFullInfo.value?.lotTitle} - ТендерМонитор` : 'О лоте' }
      ],
      link: [
        { rel: 'canonical', href: `https://tenmon.ru/search/detail/${lotFullInfo.value?.lotId}` },
      ],
    })));

    const showCreateContract = ref(false);
    const createContractPayload: any = ref(getDefaultPayload());

    function getDefaultPayload() {
      return {
        responsiblePerson: null,
        cost: null,
        lotId: null,
      }
    }

    function onHideDialog() {
      createContractPayload.value = getDefaultPayload();
    }

    function openCreateContractDialog(payload: { responsiblePerson: ManagerI, cost: CostItemI, lotId: number }) {
      createContractPayload.value = payload;
      showCreateContract.value = true;
    }

    return {
      status,
      error,
      panels,
      lotFullInfo,
      expansive,
      isAuth,
      showDialog,
      expansiveAll,
      onHideDialog,
      showCreateContract,
      createContractPayload,
      openCreateContractDialog,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/button';

.register-button {
  align-self: center;
}

#lot_detail {
  display: flex;
  flex-flow: column;
  gap: 20px;
}

#panel_action {
  display: flex;
  gap: 8px;
}
</style>
